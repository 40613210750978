import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import ProductsAndTechnology from '../views/ProductsAndTechnology.vue'
import CooperativePartner from '../views/CooperativePartner.vue'
import qy from '../views/qy+.vue'
const routes = [
  {
    path: '/',
    name: '联系我们',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/ProductsAndTechnology',
    name: '产品与技术',
    component: ProductsAndTechnology
  },
  {
    path: '/CooperativePartner',
    name: '合作伙伴',
    component: CooperativePartner
  },
  {
    path: '/qy+',
    name: '青云+',
    component: qy
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
