<template>
  <div class="el-row--flex">
    <div class="header">
      <div class="logo" @click="$router.push('/')" style="cursor: pointer">
        <img src="../assets/logo2.png" />
      </div>
      <ul>
        <li :class="{ active: $route.path == '/' }" @click="goRouter('')">
          首页
        </li>
        <li
          :class="{ active: $route.path == '/ProductsAndTechnology' }"
          @click="goRouter('ProductsAndTechnology')"
        >
          产品与技术
        </li>
        <li
          :class="{ active: $route.path == '/CooperativePartner' }"
          @click="goRouter('CooperativePartner')"
        >
          合作伙伴
        </li>
        <li
          :class="{
            active:
              $route.path == '/qy+'
          }"
          @click="goRouter('qy+')"
        >
          青云+
        </li>
        <li
          :class="{ active: $route.path == '/about' }"
          @click="goRouter('about')"
        >
          关于我们
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goRouter(path) {
      console.log(this.$router);
      this.$router.push({
        path: `/${path}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row--flex {
  z-index: 1000;
}
.header {
  padding: 0 181px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(148, 148, 148, 0.16);
  display: flex;
  height: 90px;
  .logo {
    display: flex;
    align-items: center;
    img {
      width: 150px;
      @media screen and(max-width: 768px) {
          width: (184px / 2);
          height: (58px / 2);
      }
    }
  }
  ul {
    list-style: none;
    text-align: right;
    margin: 0;
    padding: 0;
    line-height: 90px;
    flex: 1;
    white-space: nowrap;
    overflow: auto;
    li {
      display: inline-flex;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
      padding: 0 (79px / 2);
      @media screen and(max-width: 768px) {
        padding: 0 20px;
      }
      &.active {
        color: #00c6b6;
        position: relative;
        &::before {
          content: "";
          width: 40px;
          height: 4px;
          background: #00b869;
          border-radius: 2px;
          position: absolute;
          bottom: 0;
          left: calc(50% - 20px);
        }
      }
    }
  }
}
@media screen and(min-width: 0px) and (max-width: 1199px) {
  .header {
    padding: 0 20px;
  }
}
</style>
