<template>
    <div class="qy">
        <vheader class="vheader" />
        <div class="banner">
            <img src="../assets/qy+/banner.png">
        </div>
        <ul class="main sub-title">
            <li :class="{ active: active == '企业介绍' }"  @click="clickActive('企业介绍', 0)">
                企业介绍
            </li>
            <!-- <li :class="{ active: active == '愿景' }" @click="clickActive('愿景', 1)">
                愿景
            </li> -->
            <li :class="{ active: active == '使命' }" @click="clickActive('使命', 2)">
                使命
            </li>
            <li :class="{ active: active == '价值观' }" @click="clickActive('价值观', 3)">
                价值观
            </li>
            <li :class="{ active: active == '责任' }" @click="clickActive('责任', 4)">
                责任
            </li>
        </ul>
        <div class="main"> 
            <div v-if="active === '企业介绍'">
                <div class="main0">
                    <h1>深圳市青云端信息科技有限公司</h1>
                    <p>深圳市青云端信息科技有限公司成立于2013年，是一家新锐的科技产业服务平台，总部位于深圳。</p>
                    <p>青云端围绕服务科技创新助力产业发展的多元化服务包括：陪伴企业共同成长的青云企服；从事科技成果转化、技术集成创新、推动产业创新的科技产业运营服务商中科青云；秉承投资助力产业发展的初心，以高新技术和战略新兴为投资方向，服务高精特新企业资本运营的青广创投；从品牌打造到销售转化的供应链一体化服务商青聚互联。</p>
                </div>
                <div class="list0">
                    <div class="item0">
                        <div>2013年</div>
                        <div>公司成立</div>
                    </div>
                    <div class="item0">
                        <div>30+</div>
                        <div>进入城市</div>
                    </div>
                    <div class="item0">
                        <div>100+</div>
                        <div>服务企业</div>
                    </div>
                    <div class="item0">
                        <div>20万+</div>
                        <div>客户运营</div>
                    </div>
                </div>
            </div>
            <div v-if="active === '愿景'" class="main2">
                <div class="left">
                    <img src="../assets/qy+/国税取万家携手建中华.png">
                </div>
                <div class="right">
                    国税取万家，携手建中华
                </div>
            </div>
            <div v-if="active === '使命'" class="main2">
                <div class="left">
                    <img src="../assets/qy+/依托数字经济的赋能及自身的专业性推动企业成长及产业进步.png">
                </div>
                <div class="right">
                    依托数字经济的赋能及自身的专业性推动企业成长及产业进步
                </div>
            </div>
            <div v-if="active === '价值观'" class="main2">
                <div class="left">
                    <img src="../assets/qy+/共赢、共享、品质、赋能.png">
                </div>
                <div class="right">
                    共赢、共享、品质、赋能
                </div>
            </div>
            <div v-if="active === '责任'">
                <div class="main0" style="margin-bottom: 60px;">
                    <h1>责任</h1>
                    <p>深圳青云端始终秉承着“共赢，共享，品质，赋能”的企业价值观结合公益事业，发展与企业发展相匹配的体系，在文化保育、精准扶贫、社区关爱等领域持续发力。</p>
                    <p>青云端曾荣获“第五届高新杯——十大社会公益奖”，参与华夏银行发起的“华夏之星·初心图书馆”活动，参与并助力图书馆建造，及捐赠3万余册书籍。开设直播带货平台，用自身影响力扶贫助农。</p>
                    <p>与此同时，青云基金会自创立以来也一直积极践行社会责任、投身社会公益事业，持续开展扶贫济困、捐赠助学等公益行动，在教育等领域持续发力。</p>
                    <p>在未来，青云基金会将会以积极向上的态度继续发展公益事业，将青云端旗下的产业运营与社会公益服务相结合，以非盈利的形态和商业的手段推动公益服务的持续发展，助力产业发展和社会进步。</p>
                </div>
                <div class="imageList">
                    <img src="../assets/qy+/1.png">
                    <img src="../assets/qy+/2.png">
                    <img src="../assets/qy+/3.png">
                    <img src="../assets/qy+/4.png">
                    <img src="../assets/qy+/5.png">
                </div>
            </div>
        </div>
        <vfooter />
    </div>
</template>
<script>
import vheader from "@/components/header2";
import vfooter from "@/components/footer";
export default {
    name: 'Qy+',
    components: {
        vheader,
        vfooter
    },
    data() {
        return {
            active: '企业介绍',
            index: 0
        }
    },
    methods: {
        clickActive(active, index) {
            this.active = active
            this.index = index
        }
    }
}
</script>
<style lang="scss" scoped>
.qy {
    .main {
        margin: 0 181px;
    }
    .banner {
        margin-top: 90px;
        background: #424242;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    .sub-title {
      padding-left: 0;
      list-style: none;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      flex-wrap: wrap;
      li {
        min-width: 100px;
        text-align: center;
        flex: 1;
        display: inline-block;
        padding: 28px 0px;
        font-size: 16px;
        @media screen and(min-width: 0px) and (max-width: 1600px) {
          font-size: 13px;
        }
        font-weight: 400;
        color: #333333;
        line-height: 1;
        position: relative;
        cursor: pointer;
        &.active {
          font-weight: 400;
          color: #00c6b6;
          &::before {
            content: "";
            width: 40px;
            height: 4px;
            background: #00b869;
            border-radius: 2px;
            position: absolute;
            bottom: 0;
            left: calc(50% - 20px);
          }
        }
      }
    }
    .main0 {
        max-width: 1115px;
        margin: 0 auto;
        h1 {
            font-size: 32px;
            font-weight: 400;
            color: #333333;
            line-height: 1;
            margin-top: 50px;
            margin-bottom: 35px;
            text-align: center;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            color: #484848;
            line-height: 30px;
        }
    }
    .list0 {
        display: flex;
        justify-content: center;
        margin-top: 80px;
        margin-bottom: 237px;
        .item0 {
            width: 228px;
            text-align: center;
            position: relative;
            &:not(:last-child):after {
                content: '';
                width: 1px;
                height: 55px;
                background: #00C6B6;
                position: absolute;
                top: calc(50% - 27.5px);
                right: 0;
            }
            div:first-child {
                font-size: 26px;
                font-weight: bold;
                color: #484848;
                line-height: 1;
                margin-bottom: 20px;
            }
            div:nth-of-type(2) {
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #484848;
                line-height: 1;
            }
        }
    }
    .main2 {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 60px;
        .left {
            img {
                width: 579px;
                height: 408px;
                border-radius: 6px;
            }
        }
        .right {
            font-size: 32px;
            font-weight: 400;
            color: #333333;
            padding-left: 50px;
            padding-top: 50px;
        }
    }
    .imageList {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 60px;
        img {
            width: 290px;
            height: 200px;
            margin: 0 4px;
        }
    }
    @media screen and(min-width: 1200px) and (max-width: 1600px) {
        .main {
            margin: 0 151px;
        }
    }
    @media screen and(min-width: 0px) and (max-width: 1600px) {
        .main0 {
            margin: 0 auto;
            max-width: 932px;
            h1 {
                font-size: 27px;
            }
            p {
                font-size: 13px;
                line-height: 25px;
            }
        }
        .list0 {
            margin-top: 60px;
            margin-bottom: 198px;
            .item0 {
                width: 190px;
                &:not(:last-child):after {
                    height: 46px;
                    top: calc(50% - 23px);
                }
                div:first-child {
                    font-size: 22px;
                    margin-bottom: 17px;
                }
                div:nth-of-type(2) {
                    font-size: 17px;
                }
            }
        }
        .imageList {
            margin-bottom: 48px;
            img {
                width: 247px;
                height: 167px;
                margin: 0 4px;
            }
        }
    }
    @media screen and(max-width: 768px) {
        .main {
            margin: 0 20px;
        }
        .main2 {
            display: block;
            .left {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
</style>