<template>
  <div class="ProductsAndTechnology">
    <vheader class="vheader" />
    <div class="banner">
      <img src="../assets/ProductsAndTechnology/banner.png" />
    </div>
    <ul class="main sub-title">
      <li
        :class="{ active: active == '中科青云' }"
        @click="clickActive('中科青云')"
      >
        中科青云
      </li>
      <li
        :class="{ active: active == '青广创投' }"
        @click="clickActive('青广创投')"
      >
        青广创投
      </li>
      <li
        :class="{ active: active == '青聚互联' }"
        @click="clickActive('青聚互联')"
      >
        青聚互联
      </li>
      <!-- <li
        :class="{ active: active == '青云企服' }"
        @click="clickActive('青云企服')"
      >
        青云企服
      </li> -->
    </ul>
    <div class="l-main" v-for="(item, index) in list" :key="index">
      <div v-if="item.title === active" style="min-height: 464px">
        <p class="l-title">{{ item.title }}</p>
        <div class="l-content">
          <p v-for="(item2, j) in item.content" :key="j">
            {{ item2 }}
          </p>
        </div>
        <p class="l-main-title" v-if="item['l-title']">{{ item["l-title"] }}</p>
        <div class="l-main-sub-title" v-if="item['l-sub-title']">
          {{ item["l-sub-title"] }}
        </div>
        <div v-if="active === '青云企服'">
          <p class="l-main-title">企业税务痛点</p>
          <div class="l-main-sub-title line">常见税务问题</div>
          <div class="td">
            <div class="td-item"><span>涉税行为</span><span>不规范</span></div>
            <div class="td-item"><span>员工税后</span><span>收益少</span></div>
            <div class="td-item">
              <span>客观原因导致发票</span><span>难取得</span>
            </div>
            <div class="td-item">
              <span>税务成本结构</span><span>不合理</span>
            </div>
          </div>
          <div class="l-main-sub-title line">企业税筹痛点</div>
        </div>
        <div class="image-list">
          <div v-for="(item3, x) in item.image" :key="x">
            <img :src="item3.image" />
            <div class="title">
              <span v-html="item3.title"></span>
            </div>
            <div class="sub-title2">
              <span>{{ item3.sub }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray" v-if="active === '中科青云'">
      <p class="l-main-title">国内布局</p>
      <div class="l-main-sub-title">三大优势禀赋 助力企业融合发展</div>
      <div class="text-center">
        <img
          src="../assets/ProductsAndTechnology/map.png"
          style="display: block; margin: 0 auto; padding-bottom: 34px"
        />
      </div>
    </div>
    <div v-if="active === '中科青云'">
      <p class="l-main-title">七大赋能体系</p>
      <div class="l-main-sub-title">
        搭建以企业、平台、园区为中心的产业生态圈
      </div>
      <div class="text-center">
        <img
          src="../assets/ProductsAndTechnology/七大赋能体系.png"
          style="display: block; margin: 0 auto; padding-bottom: 182px"
        />
      </div>
    </div>
    <div class="bg-gray" v-if="active === '青云企服'">
      <p class="l-main-title" style="margin-bottom: 100px">青云端价值</p>
      <div class="text-center">
        <img
          src="../assets/ProductsAndTechnology/hulianwangzhengqilianjiehaohuoban.png"
          style="display: block; margin: 0 auto; padding-bottom: 70px"
        />
      </div>
      <div class="item-list">
        <div class="list-item">
          <img
            src="../assets/ProductsAndTechnology/1.png"
            style="margin: 0 auto 17px auto; padding-top: 29px; display: block"
          />
          <div class="item-title" style="margin-bottom: 40px">
            打通政企信息通道
          </div>
          <div class="content">
            青云端整合全国税收及 产业政策资源，根据企业发
            展状况与需求，将政策信息 标准化输送给服务企业，帮
            助企业第一时间掌握政策动 态，解决服务企业的“优惠政
            策信息不对称”的难题。
          </div>
        </div>
        <div class="list-item">
          <img
            src="../assets/ProductsAndTechnology/2.png"
            style="margin: 0 auto 17px auto; padding-top: 29px; display: block"
          />
          <div class="item-title" style="margin-bottom: 10px">
            提供轻咨询重落地<br />
            全程对接服务
          </div>
          <div class="content">
            重咨询”的服务模式，升 级为“轻咨询”“重落地”服务
            模式，以结果为目标，完整闭 环了从咨询到方案落地的全
            流程服务，并研发适应各种 税务场景的标准化税筹工具
            帮助企业轻松实现财税问题 解决。
          </div>
        </div>
        <div class="list-item">
          <img
            src="../assets/ProductsAndTechnology/3.png"
            style="margin: 0 auto 17px auto; padding-top: 29px; display: block"
          />
          <div class="item-title" style="margin-bottom: 40px">
            配套互联网便捷跟踪服务
          </div>
          <div class="content">
            青云端通过园区数据化 管理、SaaS云管理、crm客
            户管理系统及小程序流量接 入数据的方式，有效提高企
            业服务效率和企业信息的私 密性，做好企业税筹咨询服
            务的全流程便捷跟踪服务。
          </div>
        </div>
      </div>
    </div>
    <div v-if="active === '青云企服'" style="margin-bottom: 100px">
      <div class="l-main-sub-title line" style="padding-top: 83px">
        常见税务问题
      </div>
      <div class="sw">
        <div class="sw-item">
          <img src="../assets/ProductsAndTechnology/w1.png" />
          <div>
            <div><span>股权交易</span> 优化股权结构 重建交易主体</div>
            <div>适用:有股权转让、股权投资需求的股东、高管等。</div>
          </div>
        </div>
        <div class="sw-item">
          <img src="../assets/ProductsAndTechnology/w2.png" />
          <div>
            <div><span>灵活用工</span> 优化业务模式和用工模式</div>
            <div>适用:自由用工多、用工成本高、员工稳定性差的企业。</div>
          </div>
        </div>
      </div>
      <div class="sw">
        <div class="sw-item">
          <img src="../assets/ProductsAndTechnology/w3.png" />
          <div>
            <div><span>企业成本</span> 优化采购流程和成本结构</div>
            <div>适用:成本需要优化的企业。</div>
          </div>
        </div>
        <div class="sw-item">
          <img src="../assets/ProductsAndTechnology/w4.png" />
          <div>
            <div><span>高收入人群</span> 优化收入实现形式</div>
            <div>适用:高管、自媒体从业者、设计师、自由职业者等高收入人群。</div>
          </div>
        </div>
      </div>
    </div>
    <vfooter />
  </div>
</template>
<script>
import vheader from "@/components/header2";
import vfooter from "@/components/footer";
export default {
  components: {
    vheader,
    vfooter,
  },
  data() {
    return {
      index: 0,
      active: "中科青云",
      list: [
        {
          title: "中科青云",
          content: [
            "中科青云，隶属于中科院武汉分院育成中心，业务模块涵盖园区运营、基金及投融资服务、国家技术转移中心及产学研合作促进等科技产业项目综合解决方案。",
            "公司现有投资参股的上市供应链公司3家，全国区域内有8个技术转移中心，12家分支机构，业务覆盖全国三分之二以上的省份。",
          ],
          "l-title": "青云端三大业务板块",
          "l-sub-title": "三大优势禀赋 助力企业融合发展 ",
          image: [
            {
              title: "全国园区布局",
              sub: "强大的产业资源整合与导入能力",
              image: require("../assets/ProductsAndTechnology/30+政府园区.png"),
            },
            {
              title: "创投、基金、私募",
              sub: "金融引领创与产融结合",
              image: require("../assets/ProductsAndTechnology/22个省、直辖市、自治区.png"),
            },
            {
              title: "直投业务、投流业务、流量运营",
              sub: "数字化方式运营品牌孵化及投资",
              image: require("../assets/ProductsAndTechnology/营商环境透明有序.png"),
            },
          ],
        },
        {
          title: "青广创投",
          content: [
            "青广创投，一家由基金、证券、银行、保险等专业机构核心人员组成的专业公司。团队在金融行业均有数十年以上从业经验。公司以极富前瞻性的眼光，科学、超前的经营和管理理念，为不同客户提供多样性、差异化、全方位的投资咨询与理财规划服务。通过“投融资+投行服务”的综合模式发现价值，创造价值，与客户共同成长，最终实现客户价值最大化。",
          ],
          image: [
            {
              title: "把握行业规律",
              image: require("../assets/ProductsAndTechnology/把握行业规律.png"),
            },
            {
              title: "穿越经济周期",
              image: require("../assets/ProductsAndTechnology/穿越经济周期.png"),
            },
            {
              title: "重度垂直专业化",
              image: require("../assets/ProductsAndTechnology/重度垂直专业化.png"),
            },
            {
              title: "打造创投常青树",
              image: require("../assets/ProductsAndTechnology/打造创投常青树.png"),
            },
          ],
        },
        {
          title: "青聚互联",
          content: [
            "青聚互联，为客户提供全网整合营销、品牌推广解决方案及专业的运营优化服务。通过全面整合互联网优质媒体资源，结合数据模型分析，配合专业优化服务团队，助力提升客户长期转化效果。提供从品牌打造到销售转化的一体化营销服务，善于利用技术和大数据赋能营销解决方案，助力品牌全方位拓展客户群，核心服务包括：搜索引擎排名优化、内容营销、电商运营、KOL/KOC服务、内容号代运营等。",
          ],
          image: [
            {
              title: "新市场",
              image: require("../assets/ProductsAndTechnology/新市场.png"),
            },
            {
              title: "新生态",
              image: require("../assets/ProductsAndTechnology/新生态.png"),
            },
            {
              title: "新引擎",
              image: require("../assets/ProductsAndTechnology/新引擎.png"),
            },
            {
              title: "新营销",
              image: require("../assets/ProductsAndTechnology/新营销.png"),
            },
          ],
        },
        {
          title: "青云企服",
          content: [
            "青云企服，秉承专业服务成就客户价值的企业价值观，致力于在共享经济、企业灵活用工、小微商户业务分包等领域提供合法合规的税务解决方案。专注于让中国中小企业财税管理更简单，为中小企业提供财税咨询服务，定制税务规划解决方案，连接地方园区实现方案落地，帮助企业优化税务管理成本。",
          ],
          image: [
            {
              title:
                "企业信息通路闭塞，难<br>以充分掌握政策，承担<br>了不必要的税费支出。",
              image: require("../assets/ProductsAndTechnology/网上办事.png"),
            },
            {
              title: "政策复杂看不懂，不能<br>最大程度利用税收优惠<br>政策。",
              image: require("../assets/ProductsAndTechnology/政务公开.png"),
            },
            {
              title:
                "企业资源不足，税务筹<br>划落地能力差，导致落<br>地周期长、成本高、效果<br>差。",
              image: require("../assets/ProductsAndTechnology/投资审批.png"),
            },
          ],
        },
      ],
    };
  },
  created() {
    this.$route.query.tab && this.clickActive(this.$route.query.tab);
  },
  methods: {
    clickActive(active) {
      this.active = active;
    },
  },
};
</script>
<style lang="scss" scoped>
.ProductsAndTechnology {
  .main {
    margin: 0 181px;
  }
  .l-main {
    margin: 0 275px;
  }
  .l-main-title {
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #00c6b6;
    text-align: center;
    padding-top: 90px;
    margin-bottom: 19px;
    line-height: 1;
  }
  .l-main-sub-title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #545454;
    text-align: center;
    margin-bottom: 50px;
    position: relative;
    &.line::after {
      content: "";
      width: 24px;
      height: 3px;
      background: #00c6b6;
      border-radius: 2px;
      position: absolute;
      left: calc(50% - 12px);
      bottom: -5px;
    }
  }
  .banner {
    margin-top: 90px;
    // height: 450px;
    background: #424242;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
    // background: url('../assets/ProductsAndTechnology/banner.png') no-repeat;
    // background-size: 100%
  }
  .sub-title {
    padding-left: 0;
    list-style: none;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    flex-wrap: wrap;
    li {
      min-width: 100px;
      text-align: center;
      flex: 1;
      display: inline-block;
      padding: 28px 0px;
      font-size: 16px;
      @media screen and(min-width: 0px) and (max-width: 1600px) {
        font-size: 13px;
      }
      font-weight: 400;
      color: #333333;
      line-height: 1;
      position: relative;
      cursor: pointer;
      &.active {
        font-weight: 400;
        color: #00c6b6;
        &::before {
          content: "";
          width: 40px;
          height: 4px;
          background: #00b869;
          border-radius: 2px;
          position: absolute;
          bottom: 0;
          left: calc(50% - 20px);
        }
      }
    }
  }
  .l-title {
    font-size: 32px;
    font-weight: 400;
    color: #333333;
    line-height: 1;
    margin: 50px 0;
  }
  .l-content {
    line-height: 1;
    margin-bottom: 59px;
    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      color: #484848;
      line-height: 30px;
    }
  }
  .sw {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .sw-item {
      position: relative;
      margin: 0 40px;
      margin-bottom: 30px;
      img {
        width: 547px;
      }
      & > div {
        width: 420px;
        margin-left: 127px;
        // border: 1px solid red;
        position: absolute;
        z-index: 10;
        top: 15px;
        padding-right: 40px;
        height: 84px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > div:first-child {
          font-size: 18px;
          color: #30c8c6;
        }
        & > div:first-child > span {
          font-size: 20px;
        }
        & > div:nth-of-type(2) {
          font-size: 16px;
          font-weight: 400;
          color: #545353;
          line-height: 23px;
        }
      }
    }
  }
  .image-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 66px;
    .title {
      text-align: center;
      span {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
      }
    }
    .sub-title2 {
      font-size: 16px;
      font-weight: 400;
      color: #00c6b6;
      text-align: center;
      margin-top: 5px;
    }
    @media screen and(max-width: 768px) {
      & > div,
      img {
        width: 100%;
      }
    }
  }
  @media screen and(min-width: 1200px) and (max-width: 1600px) {
    .main {
      margin: 0 151px;
    }
  }
  @media screen and(min-width: 769px) and (max-width: 1199px) {
    .main {
      margin: 0 100px;
    }
    .l-main {
      margin: 0 150px;
    }
  }
  @media screen and(max-width: 768px) {
    .main {
      margin: 0 20px;
    }
    .l-main {
      margin: 0 30px;
    }

    .text-center {
      img {
        width: 100%;
      }
    }
    .sw .sw-item > div {
      width: 350px;
      margin-left: 100px;
      position: absolute;
      z-index: 10;
      top: 8px;
      padding-right: 20px;
      height: 84px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .sw .sw-item img {
      width: 450px;
    }
  }
  .bg-gray {
    background: #f5f5f5;
  }

  .td {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .td-item {
      width: 239px;
      height: 60px;
      background: #ffffff;
      border: 1px solid #00c6b6;
      border-radius: 6px;
      align-items: center;
      padding-left: 20px;
      display: inline-flex;
      position: relative;
      margin-bottom: 40px;
      &::after {
        content: "";
        width: 0;
        height: 0;
        border: 10px solid;
        border-color: transparent transparent transparent #00c6b6;
        position: absolute;
        top: calc(50% - 10px);
        right: -20px;
      }
      &::before {
        content: "";
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-left-color: #fff;
        position: absolute;
        top: calc(50% - 10px);
        right: -18.5px;
        z-index: 1;
      }
      span:first-child {
        font-size: 16px;
        font-weight: 400;
        color: #00c6b6;
      }
      span:nth-of-type(2) {
        font-size: 22px;
        font-weight: bold;
        color: #00c6b6;
      }
    }
  }
  .item-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .list-item {
      width: 310px;
      height: 398px;
      background: #ffffff;
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      padding: 0 50px;
      margin-bottom: 61px;
      &::after {
        content: "";
        width: 62px;
        height: 4px;
        background: #30c8c6;
        position: absolute;
        bottom: 0;
        left: calc(50% - 31px);
      }
      .content {
        font-size: 16px;
        font-weight: 400;
        color: #545353;
        line-height: 28px;
        text-align: center;
      }
      .item-title {
        font-size: 18px;
        font-weight: 400;
        color: #30c8c6;
      }
    }
  }
}
</style>