<template>
  <div class="fullPage" ref="fullPage">
    <div
      class="fullPageContainer"
      ref="fullPageContainer"
      @mousewheel="mouseWheelHandle"
      @DOMMouseScroll="mouseWheelHandle"
    >
      <div class="section">
        <vheader />
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide banner1">
              <div class="b1" :class="(page == 0) ?'fadeInRight animated':'fadeInRight animated'" style="position: absolute;left: 5%;top: 32%;">
                <img src="../assets/index/t1.png">
              </div>
            </div>
            <div class="swiper-slide banner2">
              <div :class="page == 1 ?'fadeInLeft animated':''" style="position: absolute;right: -4%;top: 32%;">
                <img src="../assets/index/t2.png">
              </div>
            </div>
            <div class="swiper-slide banner3">
              <div :class="page == 2 ?'fadeInDown animated':''" style="position: absolute;left: 5%;top: 32%;">
                <img src="../assets/index/t3.png">
              </div>
            </div>
            <div class="swiper-slide banner4">
              <div :class="page == 3 ?'fadeInUp animated':''" style="position: absolute;right: 5%;top: 32%;">
                <img src="../assets/index/t4.png">
              </div>
            </div>
            <div class="swiper-slide banner5">
              <div :class="page == 4 ?'rotateInDownLeft animated':''" style="position: absolute;right: 5%;bottom: 15%;">
                <img src="../assets/index/t5.png">
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <!-- <div class="swiper-pagination"></div> -->

          <!-- 如果需要导航按钮 -->
          <!-- <div class="swiper-button-prev"></div> -->
          <!-- <div class="swiper-button-next"></div> -->

          <!-- 如果需要滚动条 -->
          <div class="swiper-scrollbar"></div>
        </div>
        <!-- <div class="page1">
          <span>助力新经济+产业发展</span>
        </div> -->
      </div>
      <div class="section page2">
        <vheader2 class="header2" />
        <div class="content">
          <ul class="sub-title">
            <li
              :class="{ active: active == '中科青云' }"
              @click="clickActive('中科青云', 0)"
            >
              中科青云
            </li>
            <li
              :class="{ active: active == '青广创投' }"
              @click="clickActive('青广创投', 1)"
            >
              青广创投
            </li>
            <li
              :class="{ active: active == '青聚互联' }"
              @click="clickActive('青聚互联', 2)"
            >
              青聚互联
            </li>
            <!-- <li
              :class="{ active: active == '青云企服' }"
              @click="clickActive('青云企服', 3)"
            >
              青云企服
            </li> -->
            <!-- <li :class="{ active: active == '青松财税' }" @click="clickActive('青松财税', 1)">
              青松财税
            </li>
            <li :class="{ active: active == '青云众包' }" @click="clickActive('青云众包', 4)">
              青云众包
            </li> -->
          </ul>
          <div class="main">
            <div class="left">
              <div class="block">
                <img class="pre" :src="src1" />
                <img class="next" :src="src2" />
              </div>
            </div>
            <div class="right">
              <p class="right-title">{{ list[index].title }}</p>
              <p class="right-content">
                {{ list[index].content }}
              </p>
              <p class="right-more">
                <router-link
                  :to="{
                    path: '/ProductsAndTechnology',
                    query: { tab: list[index].title },
                  }"
                  >了解详情>></router-link
                >
              </p>
            </div>
          </div>
        </div>
        <vfooter />
      </div>
    </div>
  </div>
</template>

<script>
import vheader from "@/components/header";
import vheader2 from "@/components/header2";
import vfooter from "@/components/footer";
export default {
  name: "Home",
  components: {
    vheader,
    vheader2,
    vfooter,
  },
  data() {
    return {
      index: 0,
      active: "中科青云",
      src1: require("../assets/index/青松财税.png"),
      src2: require("../assets/index/青广创投.png"),
      imageList: [
        require("../assets/index/青松园区.png"),
        require("../assets/index/青广创投.png"),
        require("../assets/index/数据中心.png"),
        require("../assets/index/青松企服.png"),
      ],
      list: [
        {
          title: "中科青云",
          content:
            "中科青云，隶属于中科院武汉分院育成中心，业务模块涵盖园区运营、基金及投融资服务、国家技术转移中心及产学研合作促进等科技产业项目综合解决方案。公司现有投资参股的上市供应链公司3家，全国区域内有8个技术转移中心，12家分支机构，业务覆盖全国三分之二以上的省份。",
        },
        {
          title: "青广创投",
          content:
            "青广创投，一家由基金、证券、银行、保险等专业机构核心人员组成的专业团队，在金融行业均有数十年以上从业经验，公司以极富前瞻性的眼光，科学、超前的经营管理理念，为不同客户提供多样性、差异化、全方位的投资咨询与理财规划服务。通过“投融资+投行服务”的综合模式发现价值，创造价值，与客户共同成长，最终实现客户价值最大化。",
        },
        {
          title: "青聚互联",
          content:
            "青聚互联，为客户提供全网整合营销、品牌推广解决方案，及专业的运营优化服务。通过全面整合互联网优质媒体资源，并结合数据模型分析，配合专业优化服务团队等提升客户长期转化效果。提供从品牌打造到销售转化的一体化营销服务，善于利用技术和大数据赋能营销解决方案，助力品牌全方位拓展客户群，核心服务包括：搜索引擎排名优化、内容营销、电商运营、KOL/KOC服务、内容号代运营等。",
        },
        {
          title: "青云企服",
          content:
            "青云企服，秉承专业服务成就客户价值的企业价值观，致力于在共享经济、企业灵活用工、小微商户业务分包等领域提供合法合规的税务解决方案。专注于让中国中小企业财税管理更简单，为中小企业提供财税咨询服务，定制税务规划解决方案，连接地方园区实现方案落地，帮助企业优化税务管理成本。",
        },
      ],
      fullpage: {
        current: 1, // 当前的页面编号
        isScrolling: false, // 是否在滚动,是为了防止滚动多页，需要通过一个变量来控制是否滚动
        deltaY: 0, // 返回鼠标滚轮的垂直滚动量，保存的鼠标滚动事件的deleteY,用来判断是往下还是往上滚
      },
      page: 0
    };
  },
  watch: {},
  created() {},
  mounted() {
    let that = this
    var mySwiper = new Swiper(".swiper-container", {
      // direction: 'vertical', // 垂直切换选项
      // loop: true, // 循环模式选项
      speed: 2000,
      autoplay: true,
      // 如果需要分页器
      // virtual: true,
      pagination: {
        el: ".swiper-pagination",
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // 如果需要滚动条
      scrollbar: {
        el: ".swiper-scrollbar",
      },
      on: {
        transitionStart(swiper) {
          setTimeout(() => {
            that.page = swiper.activeIndex
          }, 1000)
        },
      },
    });
  },
  methods: {
    clickActive(active, index) {
      this.active = active;
      this.index = index;
      if (index === 0) {
        this.src2 = this.imageList[index];
        this.src1 = this.imageList[index + 1];
      } else {
        this.src2 = this.imageList[index];
        this.src1 = this.imageList[index - 1];
      }
    },
    next() {
      // 往下切换
      let len = 2; // 页面的个数
      if (this.fullpage.current + 1 <= len) {
        // 如果当前页面编号+1 小于总个数，则可以执行向下滑动
        this.fullpage.current += 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
      }
    },
    pre() {
      // 往上切换
      if (this.fullpage.current - 1 > 0) {
        // 如果当前页面编号-1 大于0，则可以执行向下滑动
        this.fullpage.current -= 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
      }
    },
    move(index) {
      this.fullpage.isScrolling = true; // 为了防止滚动多页，需要通过一个变量来控制是否滚动
      this.directToMove(index); //执行滚动
      setTimeout(() => {
        //这里的动画是1s执行完，使用setTimeout延迟1s后解锁
        this.fullpage.isScrolling = false;
      }, 1010);
    },
    directToMove(index) {
      let height = this.$refs["fullPage"].clientHeight; //获取屏幕的宽度
      let scrollPage = this.$refs["fullPageContainer"]; // 获取执行tarnsform的元素
      let scrollHeight; // 计算滚动的告诉，是往上滚还往下滚
      scrollHeight = -(index - 1) * height + "px";
      scrollPage.style.transform = `translateY(${scrollHeight})`;
      this.fullpage.current = index;
    },
    mouseWheelHandle(event) {
      // 监听鼠标监听
      // 添加冒泡阻止
      let evt = event || window.event;
      if (evt.stopPropagation) {
        evt.stopPropagation();
      } else {
        evt.returnValue = false;
      }
      if (this.fullpage.isScrolling) {
        // 判断是否可以滚动
        return false;
      }
      let e = event.originalEvent || event;
      this.fullpage.deltaY = e.deltaY || e.detail; // Firefox使用detail
      if (this.fullpage.deltaY > 0) {
        this.next();
      } else if (this.fullpage.deltaY < 0) {
        this.pre();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fullPage {
  height: 100vh; //一定要设置，保证占满
  overflow: hidden; //一定要设置，多余的先隐藏
  background-color: #fff;
  .el-row--flex {
    position: absolute;
    width: 100%;
    z-index: 1000;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #ffffff !important;
  }
}
.fullPageContainer {
  width: 100%;
  height: 100%;
  transition: all linear 0.5s;
}
.section {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.banner1 {
  background: url("../assets/index/1.png") no-repeat !important;
  background-size: 100% 100% !important;
  position: relative;
  & > div {
    opacity: 0;
  }
}
.banner2 {
  background: url("../assets/index/2.png") no-repeat !important;
  background-size: 100% 100% !important;
  position: relative;
  & > div {
    opacity: 0;
  }
}
.banner3 {
  background: url("../assets/index/3.png") no-repeat !important;
  background-size: 100% 100% !important;
  position: relative;
  & > div {
    opacity: 0;
  }
}
.banner4 {
  background: url("../assets/index/4.png") no-repeat !important;
  background-size: 100% 100% !important;
  position: relative;
  & > div {
    opacity: 0;
  }
}
.banner5 {
  background: url("../assets/index/5.png") no-repeat !important;
  background-size: 100% 100% !important;
  position: relative;
  & > div {
    opacity: 0;
  }
}
.section1 .secction1-content {
  color: #fff;
  text-align: center;
  position: absolute;
  top: 40%;
  right: 0;
  left: 0;
}
.secction1-content h1 {
  font-size: 40px;
  padding-bottom: 30px;
}
.secction1-content p {
  font-size: 20px;
}
.page1 {
  line-height: 1;
  top: calc(50% - 50px);
  text-align: center;
  position: absolute;
  width: 100%;
  span {
    font-size: 100px;
    font-family: MF LiHei (Noncommercial);
    font-weight: bold;
    color: #ffffff;
    line-height: 1;
  }
}
.page2 {
  position: relative;
  overflow-y: auto;
  .header2 {
    position: fixed;
    width: 100%;
    left: 0;
    top: 100%;
    background: #fff;
    z-index: 10000;
  }
  .content {
    padding: 90px 181px 0 181px;
    .title {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      margin-top: 39px;
    }
    .sub-title {
      padding-left: 0;
      list-style: none;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      li {
        text-align: center;
        flex: 1;
        display: inline-block;
        padding: 28px 0px;
        font-size: 16px;
        @media screen and(min-width: 0px) and (max-width: 1600px) {
          font-size: 13px;
        }
        font-weight: 400;
        color: #333333;
        line-height: 1;
        position: relative;
        cursor: pointer;
        &.active {
          font-weight: 400;
          color: #00c6b6;
          &::before {
            content: "";
            width: 40px;
            height: 4px;
            background: #00b869;
            border-radius: 2px;
            position: absolute;
            bottom: 0;
            left: calc(50% - 20px);
          }
        }
      }
    }
    .main {
      display: flex;
      margin-top: 72px;
      min-height: 403px;
      margin-bottom: 67px;
      .left {
        flex: 1;
        text-align: right;
        .block {
          position: relative;
          display: inline-block;
          width: 641px;
        }
        .pre {
          width: 463px;
          height: 326px;
          border-radius: 6px;
          position: absolute;
          right: 210px;
          top: 41px;
        }
        .next {
          width: 579px;
          height: 408px;
          border-radius: 6px;
          position: absolute;
          right: 30px;
        }
      }
      .right {
        flex: 1;
        .right-title {
          font-size: 32px;
          font-weight: 400;
          color: #333333;
          line-height: 1;
          padding-top: 30px;
          margin-bottom: 49px;
        }
        .right-content {
          font-size: 16px;
          font-weight: 400;
          color: #484848;
          line-height: 30px;
          max-width: 720px;
        }
        .right-more {
          font-size: 16px;
          font-weight: bold;
          a {
            color: #00c6b6;
          }
        }
      }
    }
  }
}

// 大屏幕bai
@media screen and(min-width: 1200px) {
}
// 屏幕zhi在dao1024px到zhuan1199之间shu
@media screen and(min-width: 768px) and (max-width: 1199px) {
  .page1 {
    top: calc(50% - 25px);
    span {
      font-size: 50px;
    }
  }
}
@media screen and (max-width: 1600px) {
  .page2 {
    .content {
      padding: 0 151px;
    }
  }
}
// 屏幕在768px到1023之间
@media screen and(min-width: 768px) and (max-width: 1599px) {
  .page1 {
    top: calc(50% - 35px);
    span {
      font-size: 70px;
    }
  }
  .page2 {
    position: relative;
    padding-top: 90px;
    .content {
      padding: 0 32px;
      .main {
        .left {
          width: 50%;
          .block {
            position: relative;
            display: inline-block;
          }
          .pre {
            width: 363px;
            height: 226px;
            border-radius: 6px;
            position: absolute;
            right: 200px;
            top: 41px;
          }
          .next {
            width: 479px;
            height: 308px;
            border-radius: 6px;
            position: absolute;
            right: 30px;
          }
        }
      }
    }
  }
}
@media screen and(max-width: 1335px) {
  .page2 .content .main {
    display: block;
    .right {
      margin-top: 300px;
      padding: 0 30px;
    }
  }
}
// 屏幕小于768之间
@media screen and(max-width: 768px) {
  .page1 {
    top: calc(50% - 15px);
    span {
      font-size: 30px;
    }
  }
  .page2 {
    position: relative;
    .content {
      padding: 0 20px;
      .main {
        .left {
          width: 100%;
        }
        .right {
          margin-top: 400px;
        }
      }
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  img {
    width: 70%;
  }
}
</style>
