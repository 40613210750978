<template>
  <div class="about">
    <vheader class="vheader" />
    <h1 class="title title1">
      联系我们
    </h1>
    <div class="main">
      <div id="dituContent">
        <img src="../assets/about/map-y.png" id="dituContent">
      </div>
      <div class="map-right">
        <div class="item">
          <div>
            <div class="item-title">深圳市青云端信息科技有限公司</div>
            <div class="span">深圳市龙华区民治街道北站社区龙华区数字创新中心（民治股份商业中心）C栋3806-3810 </div>
            <div class="span">官方电话：0755-33084779</div>
          </div>
        </div>
        <!-- <div class="item">
           <div>
            <div class="item-title">公众号</div>
            <div class="span">青云端税税念</div>
          </div> 
          <div>
            <img src="../assets/about/公众号.png">
          </div>
        </div> -->
        <div class="item">
          <div>
            <div class="item-title">合作咨询</div>
            <div class="span">陈经理</div>
            <div class="span">18579589895</div>
          </div>
          <!-- <div>
            <img src="../assets/about/合作咨询.jpg">
          </div> -->
        </div>
      </div>
    </div>
    <h1 class="title">
      集团分公司
    </h1>
    <div class="main image-list">
      <div class="img2">
        <img src="../assets/about/新田分公司.png">
        <div class="info">
          <span>深圳市青云端信息科技有限公司新田分公司</span>
          <span>湖南省永州市新田县龙泉镇工业南园电子商务物流园内</span>
        </div>
      </div>
      <div class="img2">
        <img src="../assets/about/芜湖分公司.png">
        <div class="info">
          <span>深圳市青云端信息科技有限公司芜湖分公司</span>
          <span>安徽省芜湖市繁昌县繁阳大道繁昌数字经济产业园</span>
        </div>
      </div>
      <div class="img">
        <img src="../assets/about/新余分公司.png">
        <div class="info">
          <span>深圳市青云端信息科技有限公司新余分公司</span>
          <span>江西省新余市渝水区新兴工业产业园服务中心创新</span>
        </div>
      </div>
      <div class="img">
        <img src="../assets/about/吉安分公司.png">
        <div class="info">
          <span>深圳市青云端信息科技有限公司吉安分公司</span>
          <span>江西省吉安市永丰县恩江镇永丰大道新世纪花园</span>
        </div>
      </div>
      <div class="img">
        <img src="../assets/about/烟台分公司.png">
        <div class="info">
          <span>深圳市青云端信息科技有限公司厦门分公司</span>
          <span>福建省厦门市同安区汀溪镇汀溪一里</span>
        </div>
      </div>
    </div>
    <vfooter />
  </div>
</template>
<script>
import vheader from "@/components/header2";
import vfooter from "@/components/footer";
export default {
  name: "About",
  components: {
    vheader,
    vfooter,
  },
  mounted() {
    //创建和初始化地图函数：
    function initMap() {
      createMap(); //创建地图
      setMapEvent(); //设置地图事件
      addMapControl(); //向地图添加控件
      addMarker(); //向地图中添加marker
    }

    //创建地图函数：
    function createMap() {
      var map = new BMap.Map("dituContent"); //在百度地图容器中创建一个地图
      var point = new BMap.Point(113.952407,22.551358); //定义一个中心点坐标
      map.centerAndZoom(point, 18); //设定地图的中心点和坐标并将地图显示在地图容器中
      window.map = map; //将map变量存储在全局
    }

    //地图事件设置函数：
    function setMapEvent() {
      map.enableDragging(); //启用地图拖拽事件，默认启用(可不写)
      map.enableScrollWheelZoom(); //启用地图滚轮放大缩小
      map.enableDoubleClickZoom(); //启用鼠标双击放大，默认启用(可不写)
      map.enableKeyboard(); //启用键盘上下左右键移动地图
    }

    //地图控件添加函数：
    function addMapControl() {
      //向地图中添加缩略图控件
      var ctrl_ove = new BMap.OverviewMapControl({
        anchor: BMAP_ANCHOR_BOTTOM_RIGHT,
        isOpen: 1,
      });
      map.addControl(ctrl_ove);
      //向地图中添加比例尺控件
      var ctrl_sca = new BMap.ScaleControl({ anchor: BMAP_ANCHOR_BOTTOM_LEFT });
      map.addControl(ctrl_sca);
    }

    //标注点数组
    var markerArr = [
      {
        title: "深圳市青云端信息科技有限公司",
        content: "我的备注",
        point: "113.952407|22.551358",
        isOpen: 0,
        icon: { w: 23, h: 25, l: 46, t: 21, x: 9, lb: 12 },
      },
    ];
    //创建marker
    function addMarker() {
      for (var i = 0; i < markerArr.length; i++) {
        var json = markerArr[i];
        var p0 = json.point.split("|")[0];
        var p1 = json.point.split("|")[1];
        var point = new BMap.Point(p0, p1);
        var iconImg = createIcon(json.icon);
        var marker = new BMap.Marker(point, { icon: iconImg });
        var iw = createInfoWindow(i);
        var label = new BMap.Label(json.title, {
          offset: new BMap.Size(json.icon.lb - json.icon.x + 10, -20),
        });
        marker.setLabel(label);
        map.addOverlay(marker);
        label.setStyle({
          borderColor: "#808080",
          color: "#333",
          cursor: "pointer",
        });

        (function () {
          var index = i;
          var _iw = createInfoWindow(i);
          var _marker = marker;
          _marker.addEventListener("click", function () {
            this.openInfoWindow(_iw);
          });
          _iw.addEventListener("open", function () {
            _marker.getLabel().hide();
          });
          _iw.addEventListener("close", function () {
            _marker.getLabel().show();
          });
          label.addEventListener("click", function () {
            _marker.openInfoWindow(_iw);
          });
          if (!!json.isOpen) {
            label.hide();
            _marker.openInfoWindow(_iw);
          }
        })();
      }
    }
    //创建InfoWindow
    function createInfoWindow(i) {
      var json = markerArr[i];
      var iw = new BMap.InfoWindow(
        "<b class='iw_poi_title' title='" +
          json.title +
          "'>" +
          json.title +
          "</b><div class='iw_poi_content'>" +
          json.content +
          "</div>"
      );
      return iw;
    }
    //创建一个Icon
    function createIcon(json) {
      var icon = new BMap.Icon(
        "http://api.map.baidu.com/lbsapi/creatmap/images/us_mk_icon.png",
        new BMap.Size(json.w, json.h),
        {
          imageOffset: new BMap.Size(-json.l, -json.t),
          infoWindowOffset: new BMap.Size(json.lb + 5, 1),
          offset: new BMap.Size(json.x, json.h),
        }
      );
      return icon;
    }

    // initMap(); //创建和初始化地图
  },
};
</script>
<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}
.iw_poi_title {
  color: #cc5522;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  padding-right: 13px;
  white-space: nowrap;
}
.iw_poi_content {
  font: 12px arial, sans-serif;
  overflow: visible;
  padding-top: 4px;
  white-space: -moz-pre-wrap;
  word-wrap: break-word;
}
#dituContent {
  width: 809px;
  height: 398px;
  border-radius: 5px;
  @media screen and(min-width: 1601px) {
    width: 971px;
    height: 477px;
    border-radius: 6px;
  }
  @media screen and(max-width: 768px) {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
.about {
  .title {
    margin-top: 50px;
    margin-bottom: 63px;
    font-size: 27px;
    font-weight: 400;
    color: #333333;
    text-align: center;
    @media screen and(min-width: 1601px) {
      font-size: 32px;
    }
  }
  .title1 {
    margin-top: 140px;
  }
  .main {
    display: flex;
    justify-content: center;
    margin: 0 116px;
    @media screen and(min-width: 1601px) {
      margin: 0 151px;
    }
    .map-right {
      margin-left: 95px;
      padding-top: 33px;
      .item {
        padding-bottom: 18px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        &:not(:last-child) {
          border-bottom: 1px solid #EFEFEF;
        }
        img {
          width: 76px;
          height: 76px;
          border: 1px solid #DCDCDC;
          @media screen and(min-width: 1601px) {
            width: 91px;
            height: 91px;
          }
          &:hover {
            transform:scale(3);
          }
        }
        .item-title {
          font-size: 15px;
          font-weight: bold;
          color: #484848;
          line-height: 25px;
          @media screen and(min-width: 1601px) {
            font-size: 18px;
          }
        }
        .span {
          font-size: 15px;
          font-weight: 400;
          color: #484848;
          line-height: 25px;
          @media screen and(min-width: 1601px) {
            font-size: 16px;
          }
        }
      }
    }
    @media screen and(max-width: 768px) {
      margin: 0 20px;
      flex-wrap: wrap;
      .map-right {
        padding-top: 0;
        margin-top: 30px;
        margin-left: 0px;
      }
    }
  }
  .image-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .info {
      background: rgba(0, 0, 0, 0.49);
      font-size: 15px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 25px;
      position: absolute;
      left: 0;
      bottom: 0;
      width: calc(100% - 10px);
      margin: 5px;
      display: flex;
      flex-direction: column;
      padding: 6.5px 0;
      span {
        text-align: center;
        color: #fff;
      }
    }
    .img {
      width: 414px;
      margin-left: 15px;
      margin-right: 15px;
      position: relative;
      margin-bottom: 43px;
      img {
        display: block;
        width: 414px;
        height: 375px;
      }
      @media screen and(min-width: 1601px) {
        width: 496px;
        img {
          width: 496px;
          height: 450px;
        }
      }
    }
    .img2 {
      width: 625px;
      margin-left: 25px;
      margin-right: 25px;
      position: relative;
      margin-bottom: 43px;
      img {
        display: block;
        width: 625px;
        height: 375px;
      }
      @media screen and(min-width: 1601px) {
        width: 750px;
        img {
          width: 750px;
          height: 450px;
        }
      }
    }
  }
}
</style>