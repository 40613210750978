<template>
  <router-view/>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "PingFang SC", 'Noto Sans SC';
}
.vheader {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
}
.text-center {
  text-align: center;
}

@import url(assets/animate.css);
</style>
