<template>
  <div class="CooperativePartner">
    <vheader class="vheader" />
    <div class="banner">
      <img src="../assets/CooperativePartner/banner.png">
    </div>
    <div class="main">
      <h1 class="title">合作理念</h1>
      <div class="subtitle">
        <span>
          与企业共面初创期的坎坷，依靠共赢生态体系助力团队从零起步，共寻企业长足发展，推动产业进步。
        </span>
      </div>
      <div class="image">
        <img src="../assets/CooperativePartner/合作理念.png">
      </div>
    </div>
    <div class="main">
      <h1 class="title">我们在寻找</h1>
      <div class="content">
        <div class="left" @mouseenter="enters(0)" @mouseleave="leaver(0)">
          <ul>
            <li :class="{show: index === 0}">商务合作</li>
            <li class="box" :class="{show: index === 0, hide: index !== 0}">园区资源对接</li>
            <li class="box" :class="{show: index === 0, hide: index !== 0}">中小企业孵化器</li>
            <li class="box" :class="{show: index === 0, hide: index !== 0}">投融资项目</li>
            <!-- <li class="box" :class="{show: index === 0, hide: index !== 0}">数据服务</li> -->
          </ul>
        </div>
        <div class="right" @mouseenter="enters(1)" @mouseleave="leaver(1)">
          <ul>
            <li :class="{show: index === 1}">业务合作</li>
            <li class="box" :class="{show: index === 1, hide: index !== 1}">财税筹划</li>
            <li class="box" :class="{show: index === 1, hide: index !== 1}">软件定制</li>
            <li class="box" :class="{show: index === 1, hide: index !== 1}">投融资</li>
            <!-- <li class="box" :class="{show: index === 1, hide: index !== 1}">企业服务</li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="main">
      <h1 class="title">合作伙伴</h1>
      <div class="h-content">
        <div v-for="(item, index) in list" :key="index" class="item">
          <img :src="item.image" />
          <div>
              <span>{{item.title}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main" style="margin-bottom: 70px">
      <img class="img" src="../assets/CooperativePartner/青云端期待与您的合作.png">
    </div>
    <vfooter />
  </div>
</template>
<script>
import vheader from "@/components/header2";
import vfooter from "@/components/footer";
export default {
  name: "CooperativePartner",
  components: {
    vheader,
    vfooter,
  },
  data() {
    return {
      index: '',
      list: [
        {
          title: "广发银行",
          image: require("../assets/CooperativePartner/广发银行.png"),
        },
        {
          title: "华夏银行",
          image: require("../assets/CooperativePartner/华夏银行.png"),
        },
        {
          title: "中国银行",
          image: require("../assets/CooperativePartner/中国银行.png"),
        },
        {
          title: "平安银行",
          image: require("../assets/CooperativePartner/平安银行.png"),
        },
        {
          title: "中国人寿",
          image: require("../assets/CooperativePartner/中国人寿.png"),
        },
        {
          title: "爱奇艺",
          image: require("../assets/CooperativePartner/爱奇艺.png"),
        },
        {
          title: "创维",
          image: require("../assets/CooperativePartner/创维.png"),
        },
        {
          title: "科大讯飞",
          image: require("../assets/CooperativePartner/科大讯飞.png"),
        },
        {
          title: "南极圈",
          image: require("../assets/CooperativePartner/南极圈.png"),
        },
        {
          title: "武汉中科育成",
          image: require("../assets/CooperativePartner/武汉中科育成.png"),
        },
      ],
    };
  },
  methods: {
    enters(index) {
      this.index = index
    },
    leaver(index) {
      this.index = ''
    }
  }
};
</script>
<style lang="scss" scoped>
.CooperativePartner {
  .banner {
    margin-top: 90px;
    background: #424242;
    img {
        width: 100%;
        height: auto;
        display: block;
    }
  }
  .main {
    margin: 0 166px;
    text-align: center;
    margin-bottom: 60px;
    .img {
      border-radius: 6px;
      width: 100%;
    }
    @media screen and(min-width: 768px) and (max-width: 1600px) {
      margin: 0 100px;
      margin-bottom: 33px;
    }
    @media screen and(max-width: 768px) {
      margin: 0 20px;
      margin-bottom: 33px;
    }
    .title {
      font-size: 32px;
      font-weight: 400;
      color: #333333;
      @media screen and(min-width: 768px) and (max-width: 1600px) {
        font-size: 27px;
      }
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      color: #484848;
      line-height: 30px;
    }
    .image {
      margin-top: 20px;
      img {
        // width: 100%;
        height: 344px;
      }
      @media screen and (max-width: 768px) {
        img {
          width: 100%;
          height: auto;
        }
      }
      // height: 344px;
      // background: url(../assets/CooperativePartner/合作理念.png) no-repeat;
      // background-size: 100%;
      // background-position: center;
    }
    @media screen and(max-width: 768px) {
      .image {
        width: 100%;
      }
    }
    .content {
      padding-top: 30.5px;
      display: flex;
      justify-content: center;
      .box {
        visibility:hidden;
        opacity:0;
        transform:translateY(100px);
        transition:.3s;
      }
      .show{
        animation:show .5s forwards;
      }

      .hide{
        visibility:visible;
        opacity: 1;
        transform: translateY(0);
        animation:hide .5s forwards;
      }
      @keyframes show{
        to {
          visibility:visible;
          opacity: 1;
          transform: translateY(0)
        }
      }
      @keyframes hide{
        to {
          visibility:hidden;
          opacity: 0;
          transform: translateY(100px)
        }
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          font-size: 40px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 1.8;
        }
        li:first-child {
          transform:translateY(150px);
          font-size: 40px;
          font-weight: bold;
          color: #FFFFFF;
          position: relative;
          margin-bottom: 20px;
          &::after {
            content: '';
            position: absolute;
            left: calc(50% - (73px / 2));
            bottom: 0;
            width: 73px;
            height: 4px;
            background: #3AFFA9;
            border-radius: 2px;
          }
        }
        @media screen and(min-width: 768px) and (max-width: 1600px) {
          li, li:first-child {
            font-size: 30px;
          }
        }
      }
      .left {
        background: url(../assets/CooperativePartner/商务合作.png) no-repeat;
        margin-right: 31px;
        width: 750px;
        height: 550px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and(min-width: 768px) and (max-width: 1600px) {
          width: 625px;
          height: 458px;
          font-size: 25px;
        }
      }
      .right {
        background: url(../assets/CooperativePartner/业务合作.png) no-repeat;
        margin-left: 31px;
        width: 750px;
        height: 550px;
        font-size: 40px;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and(min-width: 768px) and (max-width: 1600px) {
          width: 625px;
          height: 458px;
          font-size: 25px;
        }
      }
      @media screen and(max-width: 768px) {
        display: block;
        .left,
        .right {
          width: 100%;
          margin-left: 0px;
          margin-right: 0px;
          margin-bottom: 20px;
        }
      }
    }
    .h-content {
      margin-top: 50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @media screen and(min-width: 768px) and (max-width: 1600px) {
        margin-top: 42px;
      }
      .item {
        margin-bottom: 40px;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        @media screen and(min-width: 768px) and (max-width: 1600px) {
            margin-bottom: 33px;
        }
        div {
            margin-top: 10px;
            line-height: 1;
        }
        img {
            width: 304px;
            height: 83px;
            margin-left: 5px;
            margin-right: 5px;
            @media screen and(min-width: 768px) and (max-width: 1600px) {
                width: 253px;
                height: 69px;
                border-radius: 5px;
            }
            @media screen and(max-width: 768px) {
                width: (304px / 2.5);
                height: (83px / 2.5); 
            }
        }
      }
    }
  }
}
</style>